import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './ExpenseReimbursementEditReceipt.scss';
import { userActions } from '../../_store';
import { creditcardActions } from '../../_store';
import { clientsActions } from '../../_store';
import { payeeActions } from '../../_store';
import { projectsActions } from '../../_store';
import { tasksActions } from '../../_store';
import { subtasksActions } from '../../_store';
import { departmentActions } from '../../_store';
import { purchaseTypeActions } from '../../_store';
import { reimbursementDashboardActions } from "../../_store";
import Select from 'react-select';
import { Navigate } from 'react-router-dom';
import ImageUploading from 'react-images-uploading';
import { history } from '../../_helpers';
import CurrencyInput from 'react-currency-input-field';
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";

const billTo = [
  { value: 'C', label: 'Client', key: 1 },
  { value: 'T', label: 'Tek', key: 2 },
]

export { ReimbursementEditReceipt };

function ReimbursementEditReceipt(props) {
  const [isClearable, setIsClearable] = useState(true);
  const [billOption, selectBillTo] = useState();
  const [clientOption, setClient] = useState();
  const [creditOption, setCreditOption] = useState("false");
  const [clientExpTaskLevel, setClientExpTaskLevel] = useState();
  const [subTaskId, setSubTaskId] = useState();
  const [projectOption, setProject] = useState();
  const [submitCheck, setSubmit] = useState(false);

  const [taskOption, setTask] = useState();
  const [saveError, setError] = useState('');

  const [images, setImages] = React.useState([]);
  let [loading, setLoading] = useState(false);
  const { user: authUser } = useSelector(x => x.auth);
  const [response, setResponse] = useState(false);
  const [projectClear, setProjectClear] = useState(true);
  const [taskClear, setTaskClear] = useState(true);
  const [clientClear, setClientClear] = useState(true);

  if (!authUser) {
    return <Navigate to="/login" state={{ from: history.location }} />
  }
  let userDefaultValue = {
    user_active: authUser.user.user_active,
    user_fname: authUser.user.user_fname,
    user_id: authUser.user.user_id,
    user_lname: authUser.user.user_lname,
    user_mname: authUser.user.user_mname
  }
  const [userValue, setUserValue] = useState(userDefaultValue.user_id);
  const [ticketNumber, setTicketNumber] = useState('');
  const current = new Date();
  const Todaysdate = `${current.getFullYear()}-${(current.getMonth() + 1).toString().padStart(2, '0')}-${current.getDate().toString().padStart(2, '0')}`;
  // const Todaysdate = `${current.getFullYear()}-${(current.getMonth() >= 9 ? (current.getMonth() + 1) : '0' + (current.getMonth() + 1))}-${current.getDate() >= 9 ? current.getDate() : '0' + current.getDate()}`;
  const dispatch = useDispatch();

  const { payee } = useSelector(x => x.payee);
  const { users } = useSelector(x => x.users);
  const { clients } = useSelector(x => x.clients);
  let { projects } = useSelector(x => x.projects);
  let { tasks } = useSelector(x => x.tasks);
  let { subtasks } = useSelector(x => x.subtasks);
  let { purchaseType } = useSelector(x => x.purchaseType);

  let bindObj;
  var transDate;
  bindObj = props.formProps.modaldata;
  const targetDiv = document.getElementById("image-upload")?.getElementsByTagName("input")[0];
  if (bindObj.file_path == '') {
    targetDiv?.setAttribute('required', 'required');
  }
  if (images.length > 0) {
    targetDiv?.removeAttribute('required');
  }


  transDate = moment(bindObj.expense_trans_dt).format('YYYY-MM-DD')
  const [currentDate, setDate] = useState(transDate);

  useEffect(() => {
  
    if (bindObj.expense_trans_bill_to == 'C') {
      selectBillTo(1)
    } else if (bindObj.expense_trans_bill_to == 'T') {
      selectBillTo(2)
    } else if (bindObj.expense_trans_bill_to == 'P') {
      selectBillTo(3)

    }
    if (parseFloat(bindObj.expense_trans_amt, 10) < 0) {
      setCreditOption("true");
    }

    dispatch(userActions.getAll());
    dispatch(creditcardActions.getAll());
    dispatch(clientsActions.getAll());
    dispatch(payeeActions.getAll());
    dispatch(departmentActions.getAll());
    dispatch(purchaseTypeActions.getAll());
  }, []);

  const maxNumber = 5;

  //image upload
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  //Bill dropdown
  const selectBill = (e) => {
    if (e) {
      selectBillTo(e.key);
      onChangeGetValue({ target: { value: e?.value, name: 'expense_trans_bill_to' } })
      if (e.key == 1) {
        inputField.expense_trans_purchase_type = '';
        inputField.personal = '';
      }
      else if (e.key == 2) {
        inputField.expense_trans_bill_to_client_id = '';
      }
      else if (e.key == 3) {
        inputField.expense_trans_bill_to_client_id = '';
        inputField.expense_trans_purchase_type = '';
      }
    } else {
      selectBillTo(undefined)
    }
  };

  const selectClient = (e) => {
    if (e) {
      setClientExpTaskLevel(e.client_exp_at_task_level);
      setClientClear(true)
      const param = e.client_id;
      setClient(e.client_id);
      onChangeGetValue({ target: { value: e?.client_id, name: 'expense_trans_bill_to_client_id' } })
      inputField.expense_trans_purchase_type_id = '';
      if (e.client_exp_at_task_level != null) {
        dispatch(projectsActions.getAllById({ param }));
      } else {
        inputField.expense_trans_bill_to_project_id = '';
        inputField.expense_trans_bill_to_ts_task_id = '';

        projects = []
      }
      if (billOption == 1) {
        inputField.expense_trans_purchase_type_id = '';
      }
    } else {
      setClientClear(false)
      projects = [];
      tasks = [];
      setClient(undefined)
      onChangeGetValue({ target: { value: e?.client_id, name: 'expense_trans_bill_to_client_id' } })
      inputField.expense_trans_bill_to_project_id = '';
      inputField.expense_trans_bill_to_ts_task_id = '';
    }
  };

  const selectProject = (e) => {
    if (e) {
      setProjectClear(true);
      const param = e.project_id;
      setProject(e.project_id)
      dispatch(tasksActions.getAllById({ param }));
      onChangeGetValue({ target: { value: e?.project_id, name: 'expense_trans_bill_to_project_id' } })
    } else {
      tasks = [];
      setProject(undefined)
      setProjectClear(false);
    }
  };
  const selectTasks = (e) => {
    if (e) {
      setTaskClear(true)
      const param = e.ts_task_id;
      setTask(e.ts_task_id)
      dispatch(subtasksActions.getAllById({ param }));
    
    } else {
      setTaskClear(false)
      subtasks = [];
      setTask(undefined)
    }
  };
  const selectSubTasks = (e) => {
    if (e) {
      setSubTaskId(e.ts_task_id);
    }
  };


  //filled values
  const onChangeGetValue = e => {
    const { name, value } = e.target;
    setInputField(prevState => ({
      ...prevState,
      [name]: value,
    }));
    if (name == 'expense_trans_dt') {
      setDate(value);
    }
    if (name == 'used_by') {
      setUserValue(value);
    }
    if (name == 'expense_trans_amt') {
      inputField.expense_trans_bill_amt = value
    }
  };

  const onChangeticketNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setTicketNumber(value);
    inputField.expense_trans_ticket_number = value;
  };
  
  const selectPurchaseType = (e) => {
    if (e) {
      if (billOption == 2) {
        onChangeGetValue({ target: { value: e?.cc_trans_purchase_type_id, name: 'expense_trans_purchase_type_id' } })
      }
    }
  };
  
  function showProject() {
    if (bindObj.expense_trans_bill_to_client_id && clients.length > 0) {
      let getClientObj = clients.find(obj => obj.client_id == bindObj.expense_trans_bill_to_client_id)
      const param = getClientObj?.client_id;
      if (getClientObj.client_exp_at_task_level != null) {
        setClientExpTaskLevel(getClientObj.client_exp_at_task_level)
        dispatch(projectsActions.getAllById({ param }));
      }
    }
  }
  useEffect(() => {
    showProject();
  }, [clients])

  function showTask() {
    if (bindObj.expense_trans_bill_to_project_id && projects.length > 0) {
      let getProjectObj = projects.find(obj => obj.project_id == bindObj.expense_trans_bill_to_project_id)

      const param = getProjectObj?.project_id;
      if (param !== undefined) {
        dispatch(tasksActions.getAllById({ param }));
      }

    }

  }
  useEffect(() => {
    showTask();
  }, [projects])
  function showSubTask() {
    if (!projectOption) {
      if (bindObj.expense_trans_bill_to_task_id && tasks.length > 0) {
        let getTaskObj = tasks.find(obj => obj.ts_task_id == bindObj.expense_trans_bill_to_task_id)
        const param = getTaskObj?.ts_task_id;
        if (param !== undefined) {
          dispatch(subtasksActions.getAllById({ param }));
        }

      }
    }
  }
  useEffect(() => {
    showSubTask();
  }, [tasks])

  const radioOptionChange = (e) => {
    const value = e.target.value;
    inputField.credit = value;
    setCreditOption(value)
  }
  const [inputField, setInputField] = useState({
    expense_trans_dt: currentDate,
    expense_trans_vendor_id: bindObj.expense_trans_vendor_id ? bindObj.expense_trans_vendor_id : '',
    expense_trans_amt: bindObj.expense_trans_amt ? bindObj.expense_trans_amt.replace(/-/g, '') : null,
    expense_trans_memo: bindObj.expense_trans_memo ? bindObj.expense_trans_memo : '',
    expense_trans_status: bindObj.expense_trans_status ? bindObj.expense_trans_status : 'S',
    expense_trans_user_id: bindObj.expense_trans_user_id ? bindObj.expense_trans_user_id : userValue,
    expense_qb_account_id: null,
    expense_trans_qb_status: 'P',
    expense_trans_bill_to: bindObj.expense_trans_bill_to ? bindObj.expense_trans_bill_to : '',
    expense_trans_bill_amt: bindObj.expense_trans_amt ? bindObj.expense_trans_amt.replace(/-/g, '') : null,
    expense_trans_bill_to_client_id: bindObj.expense_trans_bill_to_client_id ? bindObj.expense_trans_bill_to_client_id : '',
    expense_trans_bill_status: 'P',
    expense_trans_ticket_number: bindObj.expense_trans_ticket_number ? bindObj.expense_trans_ticket_number : '',
    expense_trans_reviewed: 0,
    expense_trans_bill_to_ts_task_id: bindObj.expense_trans_bill_to_ts_task_id ? bindObj.expense_trans_bill_to_ts_task_id : '',
    credit: parseInt(bindObj.expense_trans_amt, 10) < 0 ? "true" : "false",
    expense_trans_purchase_type: bindObj.expense_trans_purchase_type ? bindObj.expense_trans_purchase_type : '',
    expense_trans_uploaded_by: bindObj.expense_trans_uploaded_by ? bindObj.expense_trans_uploaded_by : userValue,
    expense_trans_bill_to_project_id: bindObj.expense_trans_bill_to_project_id ? bindObj.expense_trans_bill_to_project_id : '',
    expense_trans_id: bindObj.expense_trans_id

  });
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmit(true)
    if (clientExpTaskLevel == 0 || clientExpTaskLevel == null) {
      inputField.expense_trans_bill_to_ts_task_id = null;
    } else if (clientExpTaskLevel == true) {
      if (subTaskId) {
        inputField.expense_trans_bill_to_ts_task_id = subTaskId;
      } else if (taskOption) {
        inputField.expense_trans_bill_to_ts_task_id = taskOption;
      }
    }
    if (inputField.expense_trans_amt != 0) {
      setLoading(true);
      apiCall();
    }
  }
  // function apiCall() {
  //   document.body.style.opacity = 0.5;
  //   const baseUrl = `${process.env.REACT_APP_API_URL}/Reimbursement/`;
  //   fetch(baseUrl + 'SaveExpenseReimbursement', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + authUser.jwtToken
  //     },
  //     body: JSON.stringify(inputField)
  //   }).then((response) => {
  //     setLoading(true);
  //     if (response.ok) {
  //       var formData = new FormData();
  //       if (images.length <= 0) {
  //         props.formProps.onHide();
  //         setLoading(false);
  //         document.body.style.opacity = 1;
  //         if (localStorage.getItem('reimbersementFilterObjValues')) {
  //           const reloadData = JSON.parse(localStorage.getItem('reimbersementFilterObjValues'))
  //           dispatch(reimbursementDashboardActions.getAll(reloadData));
  //         }
  //         return;
  //       }
  //       const fileData = images[0].file;
  //       formData.append("files", fileData);
  //       formData.append("expense_trans_id", bindObj.expense_trans_id);
  //       fetch(baseUrl + 'UploadExpenseReimbursementFile', {
  //         method: 'POST',
  //         headers: {
  //           'Accept': 'application/json',
  //           'Authorization': 'Bearer ' + authUser.jwtToken
  //         },
  //         body: formData
  //       }).then((response) => {
  //         setLoading(true);
  //         if (response.ok) {
  //           window.scrollTo(0, 0);
  //           setResponse(true);
  //           setTimeout(() => {
  //             setResponse(false);
  //             document.body.style.opacity = 1;
  //             props.formProps.onHide();
  //             if (localStorage.getItem('reimbersementFilterObjValues')) {
  //               const reloadData = JSON.parse(localStorage.getItem('reimbersementFilterObjValues'))
  //               dispatch(reimbursementDashboardActions.getAll(reloadData));
  //             }
  //           }, 2000);
  //           setError('');
  //           setSubmit(false);
  //           setLoading(false);
  //         } else {
  //           setError(response.statusText);
  //           setLoading(false);
  //         }
  //       });

  //     } else {
  //       setError(response.statusText);
  //       setLoading(false);
  //     }
  //   });
  // }
  function apiCall() {
    document.body.style.opacity = 0.5;
    const baseUrl = `${process.env.REACT_APP_API_URL}/Reimbursement/`;
  
    const formData = new FormData();
  
    for (const key in inputField) {
      if (Object.prototype.hasOwnProperty.call(inputField, key)) {
        formData.append(key, inputField[key]);
      }
    }
   
    if (images.length <= 0) {
     
      props.formProps.onHide();
      setLoading(false);
      document.body.style.opacity = 1;
      if (localStorage.getItem('reimbersementFilterObjValues')) {
        const reloadData = JSON.parse(localStorage.getItem('reimbersementFilterObjValues'))
        dispatch(reimbursementDashboardActions.getAll(reloadData));
      }
      // return;
    }
  
    const fileData = images && images[0]?.file;

    formData.append("files", fileData);
    formData.append("expense_trans_id", bindObj.expense_trans_id);
  
    fetch(baseUrl + 'UploadExpenseReimbursementFile', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + authUser.jwtToken
      },
      body: formData
    })
    .then((response) => {
      setLoading(true);
      if (response.ok) {
        window.scrollTo(0, 0);
        setResponse(true);
        setTimeout(() => {
          setResponse(false);
          document.body.style.opacity = 1;
          props.formProps.onHide();
          if (localStorage.getItem('reimbersementFilterObjValues')) {
            const reloadData = JSON.parse(localStorage.getItem('reimbersementFilterObjValues'))
            dispatch(reimbursementDashboardActions.getAll(reloadData));
          }
        }, 2000);
        setError('');
        setSubmit(false);
        setLoading(false);
      } else {
        setError(response.statusText);
        setLoading(false);
      }
    })
    .catch((error) => {
      console.error("Fetch error:", error);
      setError(error.message);
      setLoading(false);
      document.body.style.opacity = 1;
    });
  }

  return (
    <>

      <div className="form-overlay">
        <div className="receipts edit-receipts">
          <div className='dash-icon'>
            <div></div>
            <h6 className='dash-title edit-dash-title'>EMPLOYEE EXPENSE REIMBURSEMENT</h6>
            <div></div>
          </div>

          {response &&
            <div className='flex toast-msg'>
              <div className="alert alert-success" role="alert">
                Receipt uploaded successfully
              </div>
            </div>}

          {(subtasks.loading || tasks.loading || projects.loading || users.loading || payee.loading) &&
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>}

          {users.length > 0 && payee.length > 0 &&
            (bindObj.expense_trans_bill_to_project_id ? projects.length > 0 : true) &&
            (bindObj.expense_trans_bill_to_client_id ? clients.length > 0 : true) &&
            (bindObj.expense_trans_bill_to_task_id ? tasks.length > 0 : true) &&
            (bindObj.expense_trans_bill_to_sub_task_id ? subtasks.length > 0 : true) &&
            <main>
              <div className="container-edit container">
                <div className="">
                  <div className="receipts-form">
                    <form onSubmit={handleSubmit}>
                      <div className='dashboard-receipt-edit'>
                        {/* {radio block} */}
                        <div className="field-group">
                          <div className="field-element"></div>
                          <div className="field-element flex">
                            <div className="field">
                              <input type="radio" id="charge" value="false" checked={creditOption === "false"} name="credit_option" onChange={radioOptionChange} />
                              <label htmlFor="charge">CHARGE </label> </div>
                            <div className="field credit">
                              <input type="radio" id="credit" name="credit_option" value="true" checked={creditOption === "true"} onChange={radioOptionChange} />
                              <label htmlFor="credit">CREDIT</label></div>

                          </div>
                          <div className="field-element"></div>
                        </div>
                        {/* {radio} */}
                        <div className="field-group">
                          <div className="field-element">
                            <div className="field">
                              <div className="label">EMPLOYEE NAME<span className="mandatory-field">* </span></div>
                              {users.length > 0 &&
                                <Select options={users}
                                  isClearable={isClearable}
                                  getOptionLabel={e => e.user_id}
                                  getOptionValue={e => e.user_id}
                                  defaultValue={bindObj.expense_trans_user_id ? users.find(obj => obj.user_id == bindObj.expense_trans_user_id) : userDefaultValue}
                                  className="form-custom-class"
                                  classNamePrefix="form-custom-class-prefix" name="expense_trans_user_id"
                                  required
                                  onChange={data => onChangeGetValue({ target: { value: data?.user_id, name: 'expense_trans_user_id' } })}
                                />
                              }
                            </div>
                          </div>
                          <div className="field-element">
                            <div className="field">
                              <label htmlFor="expense_trans_dt" className="label">Transaction Date<span className="mandatory-field">* </span></label>
                              <input name="expense_trans_dt" id="date" value={currentDate} type="date" onChange={onChangeGetValue} required max={Todaysdate} />
                            </div>
                          </div>
                          <div className="field-element"></div>
                        </div>
                        <div className="field-group second-section" key="payee">
                          <div className="field-element">
                            <div className="field">
                              <label htmlFor="expense_trans_vendor_id" className="label">Vendor<span className="mandatory-field">* </span></label>

                              {payee.length > 0 &&
                                <Select options={payee}
                                  isClearable={isClearable}
                                  getOptionLabel={e => e.cc_trans_vendor_name}
                                  getOptionValue={e => e.cc_trans_vendor_id}
                                  defaultValue={payee.find(obj => obj.cc_trans_vendor_id == bindObj.expense_trans_vendor_id)}
                                  className="form-custom-class"
                                  classNamePrefix="form-custom-class-prefix" name="expense_trans_vendor_id"
                                  required
                                  onChange={data => onChangeGetValue({ target: { value: data?.cc_trans_vendor_id, name: 'expense_trans_vendor_id' } })}


                                />
                              }
                            </div>

                          </div>
                          <div className="field-element">
                            <div className="field">
                              <label htmlFor="expense_trans_amt" className="label">Amount<span className="mandatory-field">* </span></label>

                              <CurrencyInput
                                id="input-example"
                                name="expense_trans_amt"
                                decimalsLimit={2}
                                prefix="$ "
                                allowDecimals
                                placeholder="$ 0.00"
                                allowNegativeValue={false}
                                required
                                defaultValue={bindObj.expense_trans_amt?.replace(/,/g, '').replace(/-/g, '')}
                                key='amount_key'
                                onValueChange={(value, name) => onChangeGetValue({ target: { value: value, name: 'expense_trans_amt' } })}
                              />

        {inputField.expense_trans_amt == 0 &&
                                <span className="error-msg">Please enter &gt; 0 value</span>}
                            </div>
                          </div>
                          <div className="field-element">
                            <label htmlFor="expense_trans_memo" className="label">Description<span className="mandatory-field">* </span></label>
                            <div className="field">
                              <input name="expense_trans_memo" id="memo" type="text" required defaultValue={bindObj.expense_trans_memo} onChange={onChangeGetValue} />
                            </div>
                          </div>

                          <div className="field-element">
                            <label htmlFor="expense_trans_ticket_number" className="label">Ticket Number</label>
                            <div className="field">

                              <input name="expense_trans_ticket_number" id="ticketNumber" defaultValue={bindObj.expense_trans_ticket_number ? bindObj.expense_trans_ticket_number : ticketNumber} type="text" onChange={onChangeticketNumber} />


                            </div>
                          </div>
                        </div>

                        <div className="field-group third-section">
                          <div className="field-element">
                            <div className="field">
                              <label htmlFor="memo" className="label">Bill To<span className="mandatory-field">* </span></label>
                              <Select options={billTo}
                                onChange={(e) => selectBill(e)}
                                isClearable={isClearable}
                                className="form-custom-class"
                                classNamePrefix="form-custom-class-prefix"
                                name="expense_trans_bill_to"
                                defaultValue={billTo.find(obj => obj.value == bindObj.expense_trans_bill_to)}
                                required


                              />
                            </div></div>

                          {billOption == 1 ? (

                            <div className="field-element" key="client">
                              <div className="field">
                                <label htmlFor="expense_trans_bill_to_client_id" className="label">Client<span className="mandatory-field">* </span></label>
                                {clients.length > 0 &&
                                  <Select options={clients}
                                    isClearable={isClearable}
                                    getOptionLabel={e => e.client_name}
                                    getOptionValue={e => e.client_id}
                                    onChange={(e) => selectClient(e)}
                                    className="form-custom-class"
                                    classNamePrefix="form-custom-class-prefix"
                                    name="expense_trans_bill_to_client_id"
                                    defaultValue={clients.find(obj => obj.client_id == bindObj.expense_trans_bill_to_client_id)}
                                    required

                                  />
                                }
                              </div>
                            </div>) :
                            billOption == 2 ? (
                              <div className="field-element" key="department">
                                <div className="field">
                                  <label htmlFor="memo" className="label">Purchase Type</label>
                                  {purchaseType.length > 0 &&
                                    <Select options={purchaseType}
                                      isClearable={isClearable}
                                      getOptionLabel={e => e.cc_trans_purchase_type}
                                      getOptionValue={e => e.cc_trans_purchase_type_id}
                                      onChange={(e) => selectPurchaseType(e)}
                                      defaultValue={purchaseType.find(obj => obj.cc_trans_purchase_type_id == bindObj.expense_trans_purchase_type_id)}
                                      className="form-custom-class"
                                      classNamePrefix="form-custom-class-prefix"
                                      name="expense_trans_purchase_type_id"


                                    />}
                                </div>
                              </div>)

                              : (<div>

                              </div>)}
                          {/* client options*/}
                          {((clientClear && (clientExpTaskLevel != null && clientExpTaskLevel != 0) && bindObj.expense_trans_bill_to_project_id && bindObj.expense_trans_bill_to == 'C') || (projects.length > 0 && clientOption && (clientExpTaskLevel != null && clientExpTaskLevel != 0))) && (billOption != 2 && billOption != 3) ? (


                            <div className="field-element" key="Projects">
                              <div className="field">
                                <label htmlFor="memo" className="label">Project<span className="mandatory-field">* </span></label>
                                {projects.length > 0 &&
                                  <Select options={projects}
                                    isClearable={isClearable}
                                    getOptionLabel={e => e.project_name}
                                    getOptionValue={e => e.project_id}
                                    onChange={(e) => selectProject(e)}
                                    defaultValue={projects.find(obj => obj.project_id == bindObj.expense_trans_bill_to_project_id)}
                                    className="form-custom-class"
                                    classNamePrefix="form-custom-class-prefix"
                                    name="expense_trans_bill_to_project_id"

                                    required
                                  />}
                              </div>
                            </div>) : (<div className="field-element"></div>)}

                          {/* client options*/}
                        </div>

                        {/* {new dropdown} */}

                        <div className="field-group">

                          {(((clientClear && (clientExpTaskLevel != null && clientExpTaskLevel != 0) && projectClear && bindObj.expense_trans_bill_to_task_id && bindObj.expense_trans_bill_to == 'C') || (tasks.length > 0 && projectOption && clientOption && projects.length > 0) && (clientExpTaskLevel != null && clientExpTaskLevel != 0)) && (billOption != 2 && billOption != 3)) ? (
                            <div className="field-element">
                              <div className="field">
                                <label htmlFor="memo" className="label">Task<span className="mandatory-field">* </span></label>
                                {tasks.length > 0 &&
                                  <Select options={tasks}
                                    onChange={(e) => selectTasks(e)}
                                    isClearable={isClearable}
                                    getOptionLabel={e => e.task_name}
                                    getOptionValue={e => e.ts_task_id}
                                    defaultValue={tasks.find(obj => obj.ts_task_id == bindObj.expense_trans_bill_to_task_id)}
                                    className="form-custom-class"
                                    classNamePrefix="form-custom-class-prefix"
                                    name="bill_to"

                                    required

                                  />}
                              </div></div>) : <div></div>}

                          {(((clientClear && (clientExpTaskLevel != null && clientExpTaskLevel != 0) && projectClear && taskClear && bindObj.expense_trans_bill_to_sub_task_id && bindObj.expense_trans_bill_to == 'C') || (tasks.length > 0 && subtasks.length > 0 && clientOption && taskOption && projectOption && projects.length > 0) && (clientExpTaskLevel != null && clientExpTaskLevel != 0)) && (billOption != 2 && billOption != 3)) ? (
                            <div className="field-element">
                              <div className="field">
                                {subtasks.length > 0 &&
                                  <label htmlFor="memo" className="label">Sub Task<span className="mandatory-field">* </span></label>}
                                {subtasks.length > 0 &&
                                  <Select options={subtasks}
                                    onChange={(e) => selectSubTasks(e)}
                                    isClearable={isClearable}
                                    getOptionLabel={e => e.task_name}
                                    getOptionValue={e => e.ts_task_id}
                                    defaultValue={subtasks.find(obj => obj.ts_task_id == bindObj.expense_trans_bill_to_sub_task_id)}

                                    className="form-custom-class"
                                    classNamePrefix="form-custom-class-prefix"
                                    name="bill_to"

                                    required

                                  />}
                              </div></div>) : (
                            <div className="field-element"></div>)}

                          <div className="field-element"></div>
                        </div>

                        <div className='button-message'>
                          <div className="upload-images-div">

                            <div className="desk-view-r">
                              <div className="field-group">

                                <div className="field">
                                  <div className="button-wrap" id='image-upload'>

                                    <ImageUploading
                                      value={images}
                                      onChange={onChange}
                                      maxNumber={maxNumber}
                                      dataURLKey="data_url"
                                      acceptType={['jpg', 'jpeg', 'pdf', 'png', 'html']}
                                      allowNonImageType={true}

                                    >
                                      {({
                                        imageList,
                                        onImageUpload,
                                        isDragging,
                                        dragProps,
                                      }) => (

                                        <div className="upload__image-wrapper">
                                          <div className='upload-button reimbursement rei-edit'
                                            style={isDragging ? { color: 'red' } : undefined}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                          >

                                            <span className='mobile-view'><img src={'/assets/images/camera-small.png'} /> *</span>
                                            <span className='desk-view'>ATTACH REIMBURSEMENT RECEIPT *</span>
                                          </div>
                                          {images.length == 0 &&
                                            <div className="uploaded-file">
                                              <a href={bindObj.file_path} target="_blank"><img src={'/assets/images/PDF_file_icon.png?' + new Date().getTime()} /></a>
                                            </div>

                                          }
                                          &nbsp;

                                          {imageList.map((image, index) => (
                                            <div key={index} className="image-item">
                                              {image['file']?.type == "application/pdf" ? (
                                                <div><img src={'assets/images/PDF_file_icon.png'} className="App-logo" alt="logo" />
                                                </div>) : image['file']?.type == "text/html" ?
                                                (<div><img src={'assets/images/html_icon.png'} className="App-logo" alt="logo" />
                                                </div>) : (
                                                  <div>
                                                    <img src={image['data_url']} alt="" width="100" />

                                                  </div>)}
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </ImageUploading>

                                  </div>
                                </div>
                              </div>
                            </div>


                            <div className="field-group">
                              <div className="field">
                                <i className='file-format'>Image, PDF &amp; HTML files allowed</i>
                                <div className='receive-save-wrapper'>
                                  <div className="sign-btn flex">
                                    <button type="submit" className="btn btn-primary receipt-save" disabled={loading}>
                                      Submit</button>
                                    <div className="btn btn-danger receipt-cancel" onClick={props.formProps.onHide}>
                                      Cancel</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="field-group">
                              <div className="field">
                                <div className='receive-save-wrapper'>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="field-group">
                            <div className="error-msg alert alert-success mt-3 mb-0">{saveError}</div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </main>}
        </div>
      </div>

    </>
  );
}


